import URL from "constant/url";

export default function SLADetail() {
  return (
    <>
      <section className="breadcrumb-area bg-primary-gradient">
        <div className="container my-container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3">Cam kết</h2>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href={URL.Home}>Trang chủ</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <div className="container ptb-60" style={{ marginTop: "44px" }}>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="pt-blog-post">
              <div className="pt-blog-contain">
                <p>
                  Bằng công bố SLA (Cam kết chất lượng dịch vụ) này, chúng tôi
                  đảm bảo dịch vụ của Quý khách hàng đang sử dụng đạt uptime từ
                  99,99% trở lên.
                </p>
                <h4 style={{ textTransform: "none" }}>
                  <b style={{ fontWeight: "bold" }}>Chi tiết SLA:</b>
                </h4>
                <p>
                  - Khái niệm thời gian gián đoạn: là Số phút sẵn dùng tối đa mà
                  không có kết nối đến lớp ảo hóa và được tính cộng dồn trong
                  một tháng dương lịch. Thời gian gián đoạn không bao gồm phần
                  Thời gian gián đoạn có kế hoạch.
                </p>
                <p>
                  - Khái niệm thời gian gián đoạn có kế hoạch: là các khoảng
                  Thời gian gián đoạn liên quan tới mạng, phần cứng, bảo trì,
                  nâng cấp dịch vụ hoặc theo yêu cầu của cơ quan nhà nước. Chúng
                  tôi sẽ công bố hoặc thông báo tới Khách hàng ít nhất ba (03)
                  ngày hoặc 48 tiếng trong trường hợp khẩn cấp trước khi tiến
                  hành những hoạt động nêu trên.
                </p>
                <h5 style={{ textTransform: "none" }}>
                  Giá trị cam kết chất lượng
                </h5>
                <p>
                  - Tỷ lệ hoạt động hàng tháng của máy chủ ảo là từ 99,9% trở
                  lên.
                </p>
                <h5 style={{ textTransform: "none" }}>
                  <b style={{ fontWeight: "bold" }}>
                    Nội dung cam kết chất lượng
                  </b>
                </h5>
                <p>
                  Trường hợp tỷ lệ hoạt động hàng tháng của máy chủ ảo giảm
                  xuống dưới 99,9%, chúng tôi sẽ khấu trừ số tiền được tính theo
                  tỷ lệ hiển thị bên dưới cho chi phí sử dụng trong tháng xảy ra
                  sự cố.
                </p>
                <h5 style={{ textTransform: "none" }}>
                  <b style={{ fontWeight: "bold" }}>
                    Cam kết đền bù phí dịch vụ:
                  </b>
                </h5>
                <table>
                  <tbody>
                    <tr>
                      <th>Thời gian uptime hàng tháng</th>
                      <th>Thời gian ở trạng thái không sẵn sàng</th>
                      <th>Mức phí dịch vụ đền bù</th>
                    </tr>
                    <tr>
                      <td>Từ 99,9% tới 100%</td>
                      <td>Tối đa 43 phút</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Từ 99% tới ít hơn 99,9%</td>
                      <td>Tối đa 430 phút</td>
                      <td>30%</td>
                    </tr>
                    <tr>
                      <td>Từ 90% tới ít hơn 99%</td>
                      <td>Tối đa 4300 phút</td>
                      <td>50%</td>
                    </tr>
                    <tr>
                      <td>Ít hơn 90%</td>
                      <td>Hơn 4300 phút</td>
                      <td>100%</td>
                    </tr>
                  </tbody>
                </table>
                <h5 style={{ textTransform: "none" }}>
                  <b style={{ fontWeight: "bold" }}>
                    Dịch vụ không bị xem là ngưng trệ trong các trường hợp sau:
                  </b>
                </h5>
                <p>
                  - Không truy cập được dịch vụ do đường truyền từ phía khách
                  hàng, lỗi từ phía DNS.
                </p>
                <p>
                  - Không truy cập được dịch vụ do lỗi từ phía người dùng, cấu
                  hình sai.
                </p>
                <p>
                  - Không truy cập được dịch vụ do lỗi từ phía dịch vụ mạng của
                  người sử dụng, lỗi từ hệ điều hành, virus spyware v.v.. gây
                  ra.
                </p>
                <p>
                  - Không truy cập được dịch vụ do bị khóa vì vi phạm quy dịnh
                  sử dụng hoặc sử dụng các mã nguồn gây ảnh hưởng cục bộ đến hệ
                  thống.
                </p>
                <p>- Không truy cập được dịch vụ do lỗi file hệ thống.</p>
                <p>
                  - Không truy cập được dịch vụ do phần cứng bị hư hỏng (thuê
                  chỗ đặt hoặc trường hợp thuê máy chủ vật lý).
                </p>
                <p>
                  - Không truy cập được dịch vụ do hệ thống iptables, firewall,
                  lỗi dịch vụ trên máy chủ phát sinh trong quá trình sử dụng
                </p>
                <h5 style={{ textTransform: "none" }}>
                  <b style={{ fontWeight: "bold" }}>
                    Yêu cầu bồi thường và thủ tục giải quyết:
                  </b>
                </h5>
                <p>
                  Để yêu cầu bồi thường trong các trường hợp chúng tôi không đạt
                  được cam kết, khách hàng vui lòng email đến địa chỉ
                  vpsserver24h@gmail.com
                </p>
                <h5 style={{ textTransform: "none" }}>
                  Xin lưu ý: chúng tôi chỉ tiếp nhận khiếu nại duy nhất qua địa
                  chỉ email: vpsserver24h@gmail.com trong vòng 7 ngày kể từ thời
                  điểm gián đoạn dịch vụ. Quá thời hạn trên được xem như hết
                  hiệu lực cam kết. Chúng tôi có quyền từ chối khiếu nại.
                </h5>
                <p>
                  1- Tiêu đề mail ghi rõ “Yêu cầu bồi thường theo cam kết SLA”
                </p>
                <p>
                  2- Ngày và giờ cụ thể các thời điểm trạng thái không sẵn sàng.
                </p>
                <p>3- Tài khoản và IP của các dịch vụ bị ảnh hưởng.</p>
                <p>
                  4- Logs ghi lỗi hoặc hình ảnh chụp lại màn hình thời điểm xảy
                  ra lỗi ( Lưu ý: các thông tin bảo mật nhạy cảm cần được xóa mờ
                  hoặc thay bằng các dấu hoa thị).
                </p>
                <h5 style={{ textTransform: "none" }}>
                  Các yêu cầu bồi thường không cung cấp được các thông tin trên
                  sẽ không được chấp nhận hợp lệ.
                </h5>
                <p>Kết quả khiếu nại và bồi thường</p>
                <p>
                  Ngay sau khi tiếp nhận khiếu nại, bộ phận hỗ trợ của chúng tôi
                  sẽ xem xét và giải quyết theo đúng cam kết.
                </p>
                <p>
                  Trường hợp đúng cam kết: Thông báo tới khách hàng và hủy bỏ
                  khiếu nại.
                </p>
                <p>
                  Trường hợp không đúng cam kết: Hoàn lại tiền theo mức đã công
                  bố vào tài khoản của khách hàng trên website{" "}
                  <a href="https://vpsserver.vn">https://vpsserver.vn</a>. Tiền
                  hoàn lại do bồi thường chỉ được sử dụng để gia hạn hoặc đăng
                  ký mới dịch vụ. Không được phép trao đổi, nhượng lại hoặc rút
                  về tài khoản của khách hàng.
                </p>
                <p>
                  Chúng tôi có quyền thay đổi SLA mà không cần thông báo trước
                  tới khách hàng. SLA này có hiệu lực kể từ ngày 30/06/2021.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
