/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */

import FeaturePlan from "components/FeaturePlan";
import URL from "constant/url";

export default function Content() {
  return (
    <>
      {/* Hero */}
      <section className="dd-hero position-relative zindex-1">
        <span className="dd-hero-curve-shape position-absolute zindex--1 end-0 top-0" />
        <div className="container">
          <div className="col-xl-7">
            <div className="dd-hero-content">
              <h5>Dịch vụ</h5>
              <h1 className="display-3 fw-bold mb-30">
                Máy Chủ Ảo <br /> Chuyên Nghiệp
              </h1>
              <p className="lead mb-5">Đa dạng, tự động, giá hợp lý</p>
              <a
                href={URL.BuyProduct}
                className="template-btn rs-primary-btn rounded-pill"
              >
                Trải nghiệm ngay
              </a>
            </div>
          </div>
        </div>
        <img
          src="assets/img/dedicated-home/hero.png"
          alt="server"
          className="dd-hero-server"
        />
      </section>
      <FeaturePlan />
      <section className="dd-domain dd-primary-bg ptb-120 position-relative zindex-1 overflow-hidden">
        <span className="position-absolute triangle-shape zindex--1 end-0 top-0" />
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7">
              <div className="dd-domain-content">
                <h2 className="mb-40">
                  Dịch vụ ưu việt, cấu hình <br /> tối ưu
                </h2>
                <div className="domain-info dd-domain-info mt-40 d-flex flex-wrap align-items-center">
                  <div className="info-box border-0">
                    <h5 className="text-primary mb-0">VPS</h5>
                    <span>chỉ từ 115.000/Tháng</span>
                  </div>
                  <div className="info-box border-0">
                    <h5 className="danger-text mb-0">Dedicated Server</h5>
                    <span>chỉ từ 1.490.000/Tháng</span>
                  </div>
                  <div className="info-box border-0">
                    <h5 className="success-text mb-0">Proxy</h5>
                    <span>chỉ từ 60.000/Tháng</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/dedicated-home/server-2.png"
          alt="server"
          className="server"
        />
      </section>
      <section className="dd-application ptb-120 dd-section-bg overflow-hidden">
        <div className="container">
          <div className="row g-5">
            <div className="col-xl-6">
              <div className="row g-4">
                <div className="col-sm-6">
                  <div className="hm2-app-item text-center bg-white deep-shadow rounded-2">
                    <div className="feagure-img">
                      <img
                        src="assets/os/ubuntu.png"
                        alt="centos"
                        className="img-fluid"
                      />
                    </div>
                    <div className="app-content mt-4">
                      <h3 className="h6 mb-3 text-black">Ubuntu</h3>
                    </div>
                  </div>
                  <div className="hm2-app-item text-center bg-white deep-shadow rounded-2 mt-4">
                    <div className="feagure-img">
                      <img
                        src="assets/os/window.png"
                        alt="ubuntu"
                        className="img-fluid"
                      />
                    </div>
                    <div className="app-content mt-4">
                      <h3 className="h6 mb-3 text-black">Windows</h3>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="hm2-app-item text-center bg-white deep-shadow rounded-2 mt-sm-5">
                    <div className="feagure-img">
                      <img
                        src="assets/os/centos.png"
                        alt="window"
                        className="img-fluid"
                      />
                    </div>
                    <div className="app-content mt-4">
                      <h3 className="h6 mb-3 text-black">CentOS</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="dd-application-content">
                <h2 className="mb-30">
                  Đa dạng tùy chọn, đa dạng hệ điều hành
                </h2>
                <div className="accordion dd-accordion mb-50" id="dd_accordion">
                  <div className="accordion-item bg-transparent border-0">
                    <div className="accordion-header">
                      <a href="#item1" data-bs-toggle="collapse">
                        Hỗ trợ đầy đủ các hệ điều hành
                      </a>
                    </div>
                    <div
                      className="accordion-collapse collapse show"
                      id="item1"
                      data-bs-parent="#dd_accordion"
                    >
                      <div className="accordion-body ps-0 pb-0">
                        <p className="mb-0">
                          Cài đặt các phần mềm cơ bản, cài sẵn hệ điều hành cần
                          thiết
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item bg-transparent border-0">
                    <div className="accordion-header">
                      <a
                        href="#item2"
                        data-bs-toggle="collapse"
                        className="collapsed"
                      >
                        Quản lý vận hành tự động trong trang Quản trị
                      </a>
                    </div>
                    <div
                      className="accordion-collapse collapse"
                      id="item2"
                      data-bs-parent="#dd_accordion"
                    >
                      <div className="accordion-body ps-0 pb-0">
                        <p className="mb-0">
                        Mọi thao tác cài đặt, quản lý đều tự động và dễ dàng sử dụng trong trang Quản Trị.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href={URL.BuyProduct}
                  className="dd-outline-btn rounded-pill"
                >
                  Thử ngay
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="dd-feature ptb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6">
              <div className="text-center">
                <h2>Vì sao nên chọn VPSSERVER</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center g-4 mt-4">
            <div className="col-xl-4 col-lg-6">
              <div className="dd-feature-card bg-white">
                <div className="d-flex align-items-center gap-3">
                  <img
                    src="assets/img/icons/icon-2.svg"
                    alt="icon"
                    className="flex-shrink-0"
                  />
                  <h5 className="mb-0">VPS Đa Dạng</h5>
                </div>
                <p className="mt-4">
                  Đa dạng quốc gia, giá cả phù hợp với cấu hình, hỗ trợ cài đặt
                  Windows và Linux Free
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="dd-feature-card bg-white">
                <div className="d-flex align-items-center gap-3">
                  <img
                    src="assets/img/icons/icon-3.svg"
                    alt="icon"
                    className="flex-shrink-0"
                  />
                  <h5 className="mb-0">Hỗ Trợ 24/7</h5>
                </div>
                <p className="mt-4">
                  Đội ngũ IT, Chăm sóc khách hàng chuyên nghiệp, sẵn sàng cho
                  mọi tình huống, hỗ trợ nhanh chóng
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="dd-feature-card bg-white">
                <div className="d-flex align-items-center gap-3">
                  <img
                    src="assets/img/icons/icon-4.svg"
                    alt="icon"
                    className="flex-shrink-0"
                  />
                  <h5 className="mb-0">Tiêu Chuẩn Quốc Tế</h5>
                </div>
                <p className="mt-4">
                  Máy chủ được đặt và vận hành tại các Trung tâm dữ liệu Tier 3
                  chuẩn quốc tế
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="dd-feature-card bg-white">
                <div className="d-flex align-items-center gap-3">
                  <img
                    src="assets/img/icons/icon-5.svg"
                    alt="icon"
                    className="flex-shrink-0"
                  />
                  <h5 className="mb-0">Chính Sách Hoàn Trả</h5>
                </div>
                <p className="mt-4">
                  Nếu quý khách không hài lòng với dịch vụ, chúng tôi sẽ hỗ trợ
                  hoàn lại tiền không cần lý do
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="dd-feature-card bg-white">
                <div className="d-flex align-items-center gap-3">
                  <img
                    src="assets/img/icons/icon-6.svg"
                    alt="icon"
                    className="flex-shrink-0"
                  />
                  <h5 className="mb-0">SSD Chuyên Dụng</h5>
                </div>
                <p className="mt-4">
                  Sử dụng những phần cứng bền bỉ và ổ SSD để mang lại trải
                  nghiệm tốt nhất cho khách hàng
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="dd-feature-card bg-white">
                <div className="d-flex align-items-center gap-3">
                  <img
                    src="assets/img/icons/icon-4.svg"
                    alt="icon"
                    className="flex-shrink-0"
                  />
                  <h5 className="mb-0">Dễ Dàng Cài Đặt</h5>
                </div>
                <p className="mt-4">
                  Mọi thao tác cài đặt và quản lý đều tự động và dễ dàng sử dụng
                  trong phần Quản Trị.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="dd-cta pb-120">
        <div className="container">
          <div className="dd-cta-box">
            <div className="row align-items-center g-4">
              <div className="col-xl-5">
                <div className="image-wrapper">
                  <img
                    src="assets/img/dedicated-home/server-3.png"
                    alt="server"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-xl-7">
                <div className="dd-cta-content">
                  <h3 className="mb-4">
                    Trải nghiệm chất lượng vượt trội cùng VPSSERVER
                  </h3>
                  <p className="mb-4">
                    Chúng tôi tự tin với kinh nghiệm nhiều năm trên thị trường,
                    sẵn sàng đem đến cho khách hàng những dịch vụ chất lượng
                    nhất.
                  </p>
                  <div className="dd-cta-btns d-flex align-items-center flex-wrap gap-4">
                    <a href="tel:0969062462" className="dd-tels">
                      <span className="me-2">
                        <i className="fa-solid fa-phone" />
                      </span>
                      (+84) 969062462
                    </a>
                    <a href={URL.Contact} className="dd-outline-btn rounded-pill px-4">
                      Liên hệ ngay
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
