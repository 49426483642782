import URL from "constant/url";
import { useNavigate } from "react-router-dom";
import GuideRightContent from "./GuideRightContent";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide2() {
  const navigate = useNavigate();
  return (
    <>
      <section className="breadcrumb-area bg-primary-gradient">
        <div className="container my-container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3">
              Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
            </h2>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href={URL.Home}>Trang chủ</a>
                </li>
                <li className="breadcrumb-item">
                  <a href={URL.Guide}>Hướng dẫn</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      <section className="padding_top_20 padding_bottom_65">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-9">
              <div id="blog" className="single-post01">
                <div className="post-item">
                  <div className="post-item-wrap">
                    <div
                      className="post-image aos-init aos-animate"
                      data-aos="fade-up"
                    >
                      <img
                        alt=""
                        src="assets/images/blog_img_03.jpg"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                    <div className="post-item-description">
                      <section className="hm-blog-grids pt-40 pb-120 overflow-hidden">
                        <div className="container">
                          <div className="row g-5">
                            <div className="bd-content-wrapper mt-0">
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  <span style={{ fontSize: 20 }}>
                                    <span style={{ color: "#e74c3c" }}>
                                      <strong>
                                        Cách 1: Mở CMD hoặc Powershell và chạy
                                        dòng lệnh
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  <strong>
                                    <em>
                                      Net user administrator Mậtkhẩubạnmuốnđổi
                                    </em>
                                  </strong>
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  <strong>
                                    <em>
                                      ví dụ: Net user administrator
                                      vpsserver@12345
                                    </em>
                                  </strong>
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  <em>
                                    Lưu ý: Mật khẩu phải bao gồm chữ IN HOA và
                                    chữ thường (nếu thêm ký tự đặc biệt càng
                                    tốt)
                                  </em>
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  <span style={{ color: "#e74c3c" }}>
                                    <span style={{ fontSize: 20 }}>
                                      <strong>
                                        Cách 2: Thay đổi mật khẩu theo cách
                                        truyền thống
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  - Đầu tiên bạn vào&nbsp;<strong>Start</strong>
                                  &nbsp;chọn&nbsp;
                                  <strong>Control Panel</strong>
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  <a
                                    href="https://image.maxserver.com/image/qWi"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <img
                                      alt="1.png"
                                      src="https://image.maxserver.com/images/2016/04/11/1.png"
                                      style={{ width: "100%" }}
                                    />
                                  </a>
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  - Tiếp theo bạn chọn&nbsp;
                                  <strong>Change account type</strong>
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  <a
                                    href="https://image.maxserver.com/image/4Bc"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <img
                                      alt="2.png"
                                      src="https://image.maxserver.com/images/2016/04/11/2.png"
                                      style={{ width: "100%" }}
                                    />
                                  </a>
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  - Click vào tài khoản cần đổi mật khẩu, ở đây
                                  là tài khoản&nbsp;
                                  <strong>Administrator</strong>
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  <a
                                    href="https://image.maxserver.com/image/GJD"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <img
                                      alt="3.png"
                                      src="https://image.maxserver.com/images/2016/04/11/3.png"
                                      style={{ width: "100%" }}
                                    />
                                  </a>
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  - Click vào dòng&nbsp;
                                  <strong>Change the password</strong>
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  <a
                                    href="https://image.maxserver.com/image/TTr"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <img
                                      alt="4.png"
                                      src="https://image.maxserver.com/images/2016/04/11/4.png"
                                      style={{ width: "100%" }}
                                    />
                                  </a>
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <ul>
                                <li>
                                  <span
                                    style={{
                                      fontFamily: "Arial,Helvetica,sans-serif",
                                    }}
                                  >
                                    Bạn nhập mật khẩu như&nbsp;hướng dẫn ở dưới,
                                    mật khẩu cần có chữ in hoa, chữ thường, kí
                                    tự đặc biệt sẽ giúp bảo mật tốt hơn.
                                  </span>
                                </li>
                              </ul>
                              <p>&nbsp;</p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  <a
                                    href="https://image.maxserver.com/image/nUM"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    <img
                                      alt="5.png"
                                      src="https://image.maxserver.com/images/2016/04/11/5.png"
                                      style={{ width: "100%" }}
                                    />
                                  </a>
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontFamily: "Arial,Helvetica,sans-serif",
                                  }}
                                >
                                  - Cuối cùng&nbsp;bạn click vào&nbsp;
                                  <strong>Change password</strong>
                                  &nbsp;là xong.
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sidebar sticky-sidebar col-lg-3">
              <div className="sidebar-widget recent-post-widget">
                <h4 className="widget-title mt-60">Bài đăng khác</h4>
                <ul className="mt-30 rs-news-list">
                  <GuideRightContent
                    children={
                      <>
                        <li className="d-flex align-items-center">
                          <img
                            src="assets/img/rs-blog-1.png"
                            alt="not found"
                            className="img-fluid flex-shrink-0 rounded-circle"
                          />
                          <div className="rs-news-content ms-3">
                            <a
                              href=""
                              onClick={() => {
                                navigate(URL.Guide1);
                              }}
                            >
                              <h6 className="mb-0">
                                Hướng Dẫn Tăng Cường Bảo Mật VPS
                              </h6>
                            </a>
                            <span>Admin</span>
                          </div>
                        </li>
                        <li className="d-flex align-items-center">
                          <img
                            src="assets/img/rs-blog-1.png"
                            alt="not found"
                            className="img-fluid flex-shrink-0 rounded-circle"
                          />
                          <div className="rs-news-content ms-3">
                            <a
                              href=""
                              onClick={() => {
                                navigate(URL.Guide3);
                              }}
                            >
                              <h6 className="mb-0">
                                Hướng dẫn mở rộng ổ cứng trên Windows Server và
                                Windows
                              </h6>
                            </a>
                            <span>Admin</span>
                          </div>
                        </li>
                      </>
                    }
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
