import URL from "constant/url";
import ServerCategory from "./ServerCategory";
import DedicatedServerCategory from "./DedicatedServerCategory";
import ProxyCategory from "./ProxyCategory";
import S5ProxyCategory from "./S5ProxyCategory";
import MobileMenu from "layouts/MobileMenu";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Header() {
  return (
    <>
      <header className="header-section header-gradient">
        <div className="topbar">
          <div className="container">
            <div className="row align-item-center">
              <div className="col-md-6">
                <div className="topbar-left">
                  <p className="mb-0">
                    Ưu đãi lên đến <mark>20%</mark> khi mua gói 3 tháng
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="topbar-right text-end">
                  <a href={URL.Login}>Đăng nhâp</a>
                  <a href={URL.Register}>Đăng ký</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-nav">
          <div className="container">
            <div className="nav-menu bg-white">
              <div className="row align-items-center">
                <div className="col-lg-2 col-6">
                  <div className="logo-wrapper">
                    <a href={URL.Home} className="logo-black">
                      <img
                        src="assets/img/logo_w.png"
                        width={260}
                        height={67}
                        alt="logo"
                        style={{ display: "flex", margin: "auto", marginTop: '8px', marginBottom: '8px'  }}
                      />
                    </a>
                    <a href={URL.Home} className="logo-white">
                      <img
                        src="assets/img/logo_w.png"
                        width={260}
                        height={67}
                        alt="logo"
                        style={{ display: "flex", margin: "auto", marginTop: '8px', marginBottom: '8px'  }}
                      />
                    </a>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-7 d-none d-lg-block">
                  <div className="nav-wrapper">
                    <nav>
                      <ul>
                        <li>
                          <a href={URL.Home}>Trang chủ</a>
                        </li>
                        <ServerCategory />
                        <DedicatedServerCategory />
                        <ProxyCategory />
                        <S5ProxyCategory />
                        <li>
                          <a href={URL.Guide}>Hướng dẫn</a>
                        </li>
                        <li>
                          <a href={URL.Agreement}>Điều khoản</a>
                        </li>
                        <li>
                          <a href={URL.SLA}>SLA</a>
                        </li>
                        <li>
                          <a href={URL.Contact}>Liên hệ</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-6">
                  <div className="header-right d-flex align-items-center justify-content-end">
                    <button
                      className="dark-light-switcher me-2"
                      id="theme-switch"
                    >
                      <span className="light-sun">
                        <i className="fa-solid fa-sun" />
                      </span>
                      <span className="dark-moon">
                        <i className="fa-solid fa-moon" />
                      </span>
                    </button>
                    <div className="ofcanvus-btns">
                      <a href="#" className="mobile-menu-toggle d-lg-none">
                        <i
                          className="d-lg-none fa-solid fa-bars-staggered"
                          onClick={() => {
                            const el = document.getElementById("mobile-menu");
                            if (el) el.style.display = "block";
                          }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu />
    </>
  );
}
