/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { actionGetListPlan, selectListPlanDetail } from "./slice";
import { useAppDispatch, useAppSelector } from "store";
import { MANAGEMENT_URL, SITE_NAME } from "constant/app";

export default function FeaturePlan() {
  const dispatch = useAppDispatch();
  const listPlanDetail = useAppSelector(selectListPlanDetail);

  useEffect(() => {
    dispatch(actionGetListPlan({ featured: true, site_name: SITE_NAME }));
  }, [dispatch]);

  return (
    <section className="dedicated-pricing dd-section-bg ptb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="text-center">
              <h2>Những gói tiêu biểu</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-4 g-4">
          {listPlanDetail.map((item, index) => (
            <div className="col-xxl-3 col-xl-4 col-md-6" key={index}>
              <div className="dd-pricing-single bg-white rounded position-relative sale">
                <span className="sale-badge position-absolute end-0">
                  Tiêu biểu
                </span>
                <div className="dd-pricing-top text-center">
                  <img
                    src="assets/img/icons/icon-3.svg"
                    alt="icon"
                    className="img-fluid icon"
                  />
                  <h5 className="mt-3 mb-20">{item.name}</h5>
                  <div className="dd-price-amount">
                    <h2 style={{ fontSize: "26px" }}>
                      {item.price}
                      <span> VNĐ/Tháng</span>
                    </h2>
                  </div>
                </div>
                <ul className="feature-list mt-4 text-start mb-40">
                  <li>
                    <span className="me-2">
                      <i className="fa-solid fa-rocket" />
                    </span>
                    CPU: {item.cpu}
                  </li>
                  <li>
                    <span className="me-2">
                      <i className="fa-solid fa-database" />
                    </span>
                    RAM: {item.ram}
                  </li>
                  <li>
                    <span className="me-2">
                      <i className="fa-solid fa-globe" />
                    </span>
                    SSD: {item.ssd}
                  </li>
                  <li>
                    <span className="me-2">
                      <i className="fa-solid fa-gears" />
                    </span>
                    IPv4: {item.ipv4}
                  </li>
                  <li>
                    <span className="me-2">
                      <i className="fa-solid fa-briefcase" />
                    </span>
                    Băng thông: {item.bandwidth}
                  </li>
                  <li>
                    <span className="me-2">
                      <i className="fa-solid fa-briefcase" />
                    </span>
                    Ethernet port: {item.ethernet_port}
                  </li>
                </ul>
                <a
                  href={`${MANAGEMENT_URL}/buying?type=VPS&nation=${item.region}`}
                  className="template-btn rounded-pill w-100 text-center"
                >
                  Mua ngay
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
