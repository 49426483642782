/* eslint-disable jsx-a11y/iframe-has-title */

import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Footer() {
  return (
    <footer
      className="hm2-footer pt-120"
      data-background="assets/img/shapes/map-bg.png"
      style={{ backgroundImage: 'url("assets/img/shapes/map-bg.png")' }}
    >
      <div className="footer-top pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget footer-widget-1">
                <a href="index.html" className="footer-logo">
                  <img src="assets/img/logo.png" className="logo" alt="logo" />
                </a>
                <p className="text-white mt-30">
                  Đơn vị hàng đầu cung cấp VPS - Proxy chính hãng
                </p>
                <p className="text-white mt-10">
                  Chất lượng tốt nhất
                  <br />
                  Chi phí rẻ nhất
                  <br />
                  An toàn - Bảo mật Hỗ trợ tận tâm 24/7
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="footer-widget footer-widget-2 mt-2">
                <h5 className="text-white position-relative mb-5 widget-title">
                  Sản phẩm
                </h5>
                <ul className="footer-nav">
                  <li>
                    <a href={URL.ListServerVN}>VPS</a>
                  </li>
                  <li>
                    <a href={URL.ListDedicatedServerEU}>Dedicated Server</a>
                  </li>
                  <li>
                    <a href={URL.ListProxyVN}>Proxy</a>
                  </li>
                  <li>
                    <a href={URL.List922S5Proxy}>S5 Proxy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="footer-widget footer-widget-3 mt-2">
                <h5 className="text-white position-relative mb-5 widget-title">
                  Thông tin
                </h5>
                <ul className="footer-nav">
                  <li>
                    <a href={URL.Agreement}>Điều khoản</a>
                  </li>
                  <li>
                    <a href={URL.Guide}>Hướng dẫn</a>
                  </li>
                  <li>
                    <a href={URL.SLA}>SLA</a>
                  </li>
                  <li>
                    <a href={URL.Contact}>Liên hệ</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-8">
              <div className="footer-widget footer-sb-widget mt-2">
                <h5 className="text-white position-relative mb-5 widget-title">
                  Địa chỉ
                </h5>
                <p className="text-white">
                  <i
                    className="fa-solid fa-location-dot"
                    style={{ marginRight: 8 }}
                  />
                  302 Cầu Giấy - Tòa nhà Discovery Complex, Dịch Vọng Hậu,{" "}
                  <br />
                  Cầu Giấy, Hà Nội, Việt Nam
                </p>
                <p className="text-white">
                  <i
                    className="fa-solid fa-phone-volume"
                    style={{ marginRight: 8 }}
                  />
                  (+84) 969062462
                </p>
                <p className="text-white">
                  <i
                    className="fa-regular fa-envelope"
                    style={{ marginRight: 8 }}
                  />
                  vpsserver24h@gmail.com
                </p>
              </div>
              <div className="footer-social mt-5">
                <h5 className="ft-subtitle text-white position-relative d-inline-block">
                  Theo dõi chúng tôi
                </h5>
                <div>
                  <a
                    href="https://www.facebook.com/profile.php?id=61558787567319"
                    className="rounded-circle text-white"
                    style={{ marginRight: "16px" }}
                  >
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a
                    href="https://t.me/Sp_Vpsserver"
                    className="rounded-circle text-white"
                  >
                    <i className="fab fa-telegram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hm2-footer-copyright">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6 order-2 order-lg-1">
              <div className="copyright-txt mt-3 mt-lg-0">
                <p className="mb-0 text-white">
                  © 2024 VPSSERVER. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
